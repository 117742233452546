<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">Mobile Offers</h4>
                            </div>
                            <div class="col-md-10 text-right">
                                <b-button v-b-modal.modal-center variant="success">
                                    <i class="ri-add-line  align-middle mr-2"></i> Add an offer
                                </b-button>
                                <b-modal
                                        id="modal-center"
                                        centered
                                        title="Add Offer"
                                        size="lg"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form @submit.prevent="createOffer">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label >Name</label>
                                                <input
                                                        type="text"
                                                        v-model="name"
                                                        class="form-control"
                                                        placeholder="Enter name"
                                                        required
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >frequency</label>
                                                <input
                                                        type="text"
                                                        v-model="frequency"
                                                        class="form-control"
                                                        placeholder="Enter frequency"
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >Data Amount</label>
                                                <input
                                                        type="text"
                                                        v-model="data_amount"
                                                        class="form-control"
                                                        placeholder="Data Amount"
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >Price</label>
                                                <input
                                                        type="number"
                                                        v-model="price"
                                                        class="form-control"
                                                        placeholder="Price"
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >Rationed</label>
                                                <multiselect v-model="rationed" :options="option" :multiple="false" placeholder=" Rationed"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Data only</label>
                                                <multiselect v-model="data_only" :options="option" :multiple="false" placeholder=" Data Only"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Category</label>
                                                <multiselect v-model="cat" :options="categoryName" label="title" track-by="title" @select="selected" :multiple="false" placeholder=" Select Category"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Hot offer</label>
                                                <multiselect v-model="hot_offer" :options="option" :multiple="false" placeholder="Hot Offer"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Description</label>
                                                <textarea
                                                        v-model="descrip"
                                                        class="form-control"
                                                        :maxlength="225"
                                                        rows="1"
                                                        placeholder="Comment"
                                                ></textarea>
                                            </div>

                                        </div>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4"
                                                type="submit"
                                                @click="hideModal"
                                        >Save</button>
                                    </form>

                                </b-modal>
                                <b-modal
                                        id="modal-update"
                                        centered
                                        title="Update Offer"
                                        size="lg"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form @submit.prevent="updateOffer">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label >Name</label>
                                                <input
                                                        type="text"
                                                        v-model="selectedOffer.name"
                                                        class="form-control"
                                                        placeholder="Enter name"
                                                        required
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >frequency</label>
                                                <input
                                                        type="text"
                                                        v-model="selectedOffer.frequency"
                                                        class="form-control"
                                                        placeholder="Enter frequency"
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >Data Amount</label>
                                                <input
                                                        type="text"
                                                        v-model="selectedOffer.data_amount"
                                                        class="form-control"
                                                        placeholder="Data Amount"
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >Price</label>
                                                <input
                                                        type="number"
                                                        v-model="selectedOffer.price"
                                                        class="form-control"
                                                        placeholder="Price"
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label >Rationed</label>
                                                <multiselect v-model="selectedOffer.rationed" :options="option" :multiple="false" placeholder=" Rationed"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Data only</label>
                                                <multiselect v-model="selectedOffer.data_only" :options="option" :multiple="false" placeholder=" Data Only"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Category</label>
                                                <multiselect v-model="selectedOffer.cat" :options="categoryName" label="title" track-by="title" @select="selected" :multiple="false" placeholder=" Select Category"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Hot offer</label>
                                                <multiselect v-model="selectedOffer.hot_offer" :options="option" :multiple="false" placeholder="Hot Offer"></multiselect>
                                            </div>
                                            <div class="col-md-6">
                                                <label >Description</label>
                                                <textarea
                                                        v-model="selectedOffer.descrip"
                                                        class="form-control"
                                                        :maxlength="225"
                                                        rows="1"
                                                        placeholder="Comment"
                                                ></textarea>
                                            </div>

                                        </div>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4"
                                                type="submit"
                                                @click="hideModal"
                                        >Save</button>
                                    </form>

                                </b-modal>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="offerData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(actions)="{item}">
                                    <!--                                    <div class="row justify-content-end">-->
                                    <b-button v-b-tooltip.hover v-b-modal.modal-update title="Edit" class="actionBut" @click="update(item)" variant="success">
                                        <i class="ri-pencil-fill align-middle "></i>
                                    </b-button>
                                    <b-button v-b-tooltip.hover title="Delete" class="actionBut" @click="del(item)" variant="danger">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>

                                    <!--                                    </div>-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import firebase from "../../../firebaseInit";
    const db = firebase.firestore();

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "Fixed Offer",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader,Multiselect },
        data() {
            return {
                categoryData: [],
                categoryName: null,
                offerData: [],
                rationed: "",
                price:"",
                name:"",
                frequency:"",
                data_only: "",
                data_amount:"",
                descrip:"",
                cat:null,
                tableData: "",
                categoryId:"",
                hot_offer:"",
                selectedOffer:"",
                option:[
                    "True",
                    "False"
                ],
                title: "View Fixed Offer",
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "Fixed Offer",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "id", sortable: true },
                    { key: "name", sortable: true },
                    { key: "frequency", sortable: false },
                    { key: "data_amount", sortable: false },
                    { key: "price", sortable: false },
                    { key: "actions", sortable: false }
                ],
                value:null,


            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.offerData.length;
            }
        },
        mounted(){
            // Set the initial number of items
            this.totalRows = this.items.length;
            db.collection("fixed_offers")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                        let d=new Date(doc.data().updated_at)
                        this.offerData.push({
                            id: doc.id,
                            price: doc.data().price,
                            name: doc.data().name,
                            description: doc.data().description,
                            frequency: doc.data().frequency,
                            data_amount: doc.data().data_amount,
                            data_only: doc.data().data_only,
                            category: doc.data().category,
                            rationed:doc.data().rationed,
                            hot_offer:doc.data().hot_offer,
                            updated_at:d,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                    console.log(this.offerData)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

            db.collection("fixed_categories")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                        let d=new Date(doc.data().updated_at)
                        this.categoryData.push({
                            id: doc.id,
                            title: doc.data().title,
                            description: doc.data().description,
                            updated_at:d,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                    console.log(this.categoryData)
                    this.categoryName=this.categoryData
                    //this.categoryName=this.categoryData.map(e=>(e.title))
                    console.log(this.categoryName)
                    //  return this.categoryData
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        },
        methods: {
            createOffer() {
                db.collection("fixed_offers")
                    .add({
                        rationed: this.rationed,
                        price: this.price,
                        name: this.name,
                        frequency: this.frequency,
                        data_only: this.data_only,
                        data_amount: this.data_amount,
                        descrip:this.descrip,
                        category:this.categoryId,
                        hot_offer:this.hot_offer,
                        created_at:new Date().getTime(),
                        updated_at:new Date().getTime(),
                    })
                    .then(() => {
                        console.log("Offer created successfully !");
                    })
                    .catch((error) => {
                        console.error("Error: ", error);
                    });
            },
            del(e) {
                const  index = this.offerData.indexOf(e)
                console.log(e.id)
                db.collection("fixed_offers")
                    .doc(e.id)
                    .delete()
                    .then(() => {
                        this.offerData.splice(index,1)
                        console.log("Offer successfully deleted!");

                    })
                    .catch((error) => {
                        console.error("Error removing document: ", error);
                    });
            },
            selected(cat){
                console.log(cat)
                console.log(cat.id)
                this.categoryId=cat.id;
                console.log(this.categoryId)
            },
            update(e) {
                console.log(e);
                console.log(e);
                console.log(e.id);
                //start loading
                db.collection('fixed_offers').doc(e.id).get()
                    .then((doc)=>{
                        //doc.data();
                        console.log(doc.data())
                        this.selectedOffer={
                            id:doc.id,
                            ...doc.data(),
                        };
                        console.log(this.selectedOffer)
                        return this.selectedOffer
                    }).catch(e=>{
                    console.error(e);
                    //toast
                }).finally(()=>{
                    //stop loading
                })
            },
            updateOffer() {
                db.collection("fixed_offers")
                    .doc(this.selectedOffer.id)
                    .update({
                        ...this.selectedOffer
                    })
                    .then(() => {
                        console.log("Offer successfully updated!");
                    })
                    .catch((error) => {
                        console.error("Error updating document: ", error);
                    });
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left:15px;
    }
</style>